import { Helmet } from 'react-helmet'
import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import CTA from '../components/CTA'
import Header from '../components/Header'
import {
  SplitContent,
  SplitFigure,
  Container,
  SplitSection,
  ReadMore,
  Text,
} from '../components/styles'
import Arrow from '../components/Arrow'
import styled from 'styled-components'
import Hero from '../components/Hero'

const Value = styled(Container).attrs({
  bg: '#fff',
  medium: true,
  shadow: true,
})`
  margin-bottom: 1.5rem;
  padding: 3rem;

  > div > div:first-child {
    padding-right: 3rem;
  }
`

const StyledFlex = styled(Flex)`
  @media (max-width: 639px) {
    flex-direction: column;
    > div {
      margin-bottom: 2rem;
    }
  }
`

const StyledHero = styled(Hero)`
  display: flex;
  align-items: center;
  width: 100vw;
  margin: 0;
`
const Info = styled.div`
  z-index: 10;
  background: #fff;
  padding: 1rem 3rem;
  border-radius: 8px;
  margin-top: 30vh;
  text-align: center;
  max-width: 600px;
  h3 {
    color: ${(props) => props.theme.colors.dark};
    text-shadow: none;
  }
`

/* prettier-ignore */
interface IProps {
  data: {
    coast: {
      childImageSharp: {
        fluid: object
      }
    };
    ecology: {
      childImageSharp: {
        fluid: object
      }
    };
    top: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => (
  <Layout header="light">
    <Helmet>
      <title>Dr Sellars - Plastic and Cosmetic Surgeon Sydney</title>
      <meta
        name="description"
        content="Meet Dr Graham Sellars, Plastic and Cosmetic Surgeon in Sydney, NSW providing breast implants, breast enlargement, rhinoplasty nose jobs and eyelid surgery."
      />
    </Helmet>
    <StyledHero height="90vh" noForm={true} bgImage={data.top.childImageSharp}>
      <Info>
        <h3>
          <Box mb={3}>
            <h5>Dr Graham Sellars</h5>
          </Box>
          Previous Chairman of the NSW Chapter of the Australian Society of Plastic Surgeons
        </h3>
      </Info>
    </StyledHero>

    <Container padY={true} medium={true}>
      <SplitSection>
        <SplitFigure>
          <Img
            fluid={data.meetSellars.childImageSharp.fluid}
            alt="Turboclicks business woman smiling"
          />
        </SplitFigure>
        <SplitContent>
          <h2>Meet Dr Sellars</h2>
          <p>
            Amongst the many things you may look for when choosing a Plastic
            Surgeon – reputation, experience, cost, compatibility – none can be
            more important than how well qualified the surgeon is to carry out
            the procedure you are considering. Always look for the title
            "Specialist Plastic Surgeon" to ensure your surgeon is fully
            qualified.
          </p>

          <p>
            In addition to the establishment and operation of his own practice
            since 2002, Dr Sellars is the supervisor of Plastic Surgery training
            at the Sydney Adventist Hospital and has served on the hospital’s
            Medical Advisory Committee. He was Chairman of the NSW Chapter of the Australian
            Society of Plastic Surgeons from 2011 to 2013 and
            participates in the training of future Plastic Surgeons in
            Australia.
          </p>

          <p>
            After graduating from the University of NSW in 1992, Dr Sellars’
            initial surgical training was at the Royal North Shore Hospital. He
            then completed his specialist training in Plastic and Reconstructive
            surgery at several major teaching hospitals in Sydney, obtaining the
            RACS fellowship in 2001.
          </p>

          <h4>Post Graduate Training</h4>
          <p>
            Dr Sellars’ post-graduate surgical training consisted of ten years,
            six of which were specifically devoted to Plastic Surgery. His
            training program was overseen by the Royal College of Surgeons, the
            only statutory body for the training of surgeons in Australia.
          </p>

          <h4>International Experience</h4>
          <p>
            After completing a Fellowship in Cosmetic Surgery, Dr Sellars gained
            further experience working with Plastic Surgeons in London, Paris
            and Stockholm, focusing on Breast Augmentation and Facial Cosmetic
            Surgery.
          </p>

          <ReadMore to="/contact">
            Contact Dr Sellars Today
            <Arrow rotate="-90deg" />
          </ReadMore>
        </SplitContent>
      </SplitSection>
    </Container>

    <Container medium={true}>
      <Img
        style={{ borderRadius: 4 }}
        fluid={data.teamTwo.childImageSharp.fluid}
      />
    </Container>

    <Box mb={0}>
      <Container full={true} padY={true}>
        <Container medium={true}>
          <h2>Memberships</h2>
          <ul style={{ fontSize: 25 }}>
            <li>Royal Australasian College of Surgeons</li>
            <li>Australian Society of Plastic Surgeons</li>
            <li>Australasian Society of Aesthetic Plastic Surgeons</li>
            <li>Australian Medical Association</li>

            <li>Kuring-gai District Medical Association</li>

            <li>American Society of Plastic Surgeons</li>
          </ul>
        </Container>
      </Container>
    </Box>

    <Box mb={6}>
      <Container full={true}>
        <Container medium={true}>
          <h2>Dr Sellars Operates at</h2>
          <ul style={{ fontSize: 25 }}>
            <li>The San Clinic</li>
            <li>The Sydney Adventist Hospital</li>
            <li>The San Day Surgery at Hornsby</li>
          </ul>
        </Container>
      </Container>
    </Box>

    <Flex id="team" justifyContent="center" style={{marginBottom: 50}}>
      <h1>Our Team</h1>
    </Flex>
    <Container
      style={{ borderRadius: 4 }}
      bg="#fef1f7"
      padY={true}
      medium={true}
    >
      <SplitSection style={{ marginLeft: 0 }}>
        <SplitFigure>
          <Img
            fluid={data.team.childImageSharp.fluid}
            alt="Turboclicks business woman smiling"
          />
        </SplitFigure>
        <SplitContent>
          <h5>Meet our support Team</h5>
          <p>
            Our caring support teams work hard to ensure that every aspect of
            your experience with us is first class and you feel in the utmost
            comfort.
          </p>
          <ReadMore to="/contact">
            Contact the team
            <Arrow rotate="-90deg" />
          </ReadMore>
        </SplitContent>
      </SplitSection>
      <Flex justifyContent="center" style={{ marginTop: 25 }}>
        <h3>
          Our team is made up of hard-working, fun, and passionate people. Come
          in and say hello to our administration and nursing staff.
        </h3>
      </Flex>
    </Container>

    <Container padY={true} medium={true}>
      <Flex style={{paddingBottom: 50}} justifyContent="center">
        <h1>Meet our nursing team</h1>
      </Flex>
      <SplitSection>
        <SplitFigure smallImage={true}>
          <Img
            fluid={data.michelle.childImageSharp.fluid}
            alt="Turboclicks business woman smiling"
          />
        </SplitFigure>
        <SplitContent>
          <h4>Michelle</h4>

          <p>
            Michelle is our Practice Manager, Registered Nurse and nurse
            injector. She has a Bachelor of Applied Science in Nursing and a
            Certificate in Operating Suite Nursing.
          </p>

          <p>
            Michelle worked in operating theatres at Prince of Wales Hospital
            Randwick for two years before commencing her Certificate in
            Operating Suite at Royal North Shore Hospital in 1992. She gained a
            role as Clinical Nurse Specialist in Neurosurgery and Plastic
            Surgery operating theatres at Royal North Shore Hospital for 9 years
            before completing clinical training in cosmetic injectable
            treatments.
          </p>

          <p>
            This has enabled her to become a qualified cosmetic injector, and
            has she been performing such treatments for over 20 years.
          </p>

          <p>
            Michelle strives to create a welcoming environment where clients
            have a positive experience and leave feeling supported and cared for
            during their post-treatment period.
          </p>
        </SplitContent>
      </SplitSection>
      <Box mt={6} />
      <SplitSection>
        <SplitFigure smallImage={true}>
          <Img
            fluid={data.julie.childImageSharp.fluid}
            alt="Turboclicks business woman smiling"
          />
        </SplitFigure>
        <SplitContent>
          <h4>Julie</h4>

          <p>
            Julie is our Practice and Peri-operative Nurse. Julie has worked in
            Plastic Surgery operating theatres for over 25 years and is
            extremely experienced in all aspects of Plastic and Cosmetic
            Surgery. Her expertise makes her an invaluable team member.
          </p>

          <p>
            Julie assists Dr Sellars in operating theatre as well as assisting
            Dr Sellars with post-operative follow-up visits in the rooms. Julie
            loves being the face patients recognize before, during and after
            surgery, and they love the continuity of nursing care they receive.
          </p>
        </SplitContent>
      </SplitSection>
    </Container>

    <Container padY={true} medium={true}>
      <Flex flexDirection="column" style={{paddingBottom: 50}} alignItems="center">
        <h1 style={{ marginBottom: '2rem' }}>Meet our support team</h1>
        <p>
          With exceptional interpersonal skills, the girls are always
          professional and will ensure every possible step is taken to make you
          feel comfortable and at ease. If they cannot answer your queries
          immediately, they will return your call promptly with the information
          you require.
        </p>

        <p>
          Please be reassured we all understand the importance of
          confidentiality, particularly in relation to cosmetic treatments, and
          we are extremely careful when returning phone calls.
        </p>
      </Flex>
      <Box mt={6} />
      <SplitSection>
        <SplitFigure smallImage={true}>
          <Img
            fluid={data.christine.childImageSharp.fluid}
            alt="Turboclicks business woman smiling"
          />
        </SplitFigure>
        <SplitContent>
          <h4>Christine</h4>

          <p>
            Christine is originally from Wales, UK and moved to Australia 25
            years ago with her family after also living in Canada and Hong Kong.
            Having worked as a relocation consultant for 15 years and needing a
            change of career, a morning walk with Michelle and their beloved
            dogs Chelsea and Dafydd resulted in Chris joining the practice in
            2015. Chris also has a background in customer service and
            administration.{' '}
          </p>

          <p>
            In her spare time Chris volunteers as a telephone crisis supporter
            at Lifeline, enjoys life and is constantly asking Michelle for time
            off to visit her extensive family overseas!
          </p>
        </SplitContent>
      </SplitSection>
      <Box mt={6} />
      <SplitSection>
        <SplitFigure smallImage={true}>
          <Img
            fluid={data.leanne.childImageSharp.fluid}
            alt="Turboclicks business woman smiling"
          />
        </SplitFigure>
        <SplitContent>
          <h4>Leanne</h4>

          <p>
            Leanne has a background in office administration and customer
            service, and is heavily involved in community service on the Central
            Coast. Leanne’s volunteer work has included life engagement and
            pastoral care at Hammondcare Nursing Home, and with the organisation
            “Look Good Feel Better” helping people with cancer gain self
            confidence and self esteem again.
          </p>

          <p>
            Leanne has a natural ability of putting elderly patients at ease and
            goes above and beyond her secretarial role. She has boundless energy
            and often gets the lights turned off on her because she won't leave
            the office!
          </p>
        </SplitContent>
      </SplitSection>
    </Container>
  </Layout>
)

export const query = graphql`
  query AboutPageQuery {
    top: file(relativePath: { eq: "about-us.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    meetSellars: file(relativePath: { eq: "meet-sellars.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    michelle: file(relativePath: { eq: "michelle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    julie: file(relativePath: { eq: "julie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    leanne: file(relativePath: { eq: "leanne.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    chris: file(relativePath: { eq: "chris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    christine: file(relativePath: { eq: "christine.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    team: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    teamTwo: file(relativePath: { eq: "sellars-team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
